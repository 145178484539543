<template>
  <SiteNav/>
  <template v-if="ready">
      <router-view v-slot="{ Component }" >
        <transition name="fade-fast" mode="out-in" appear>
          <div :key="$route.name">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
  </template>
  <Cookies/>
</template>

<script>
import SiteNav from "@/components/SiteNav";
import {useLangRouter} from "@/extensions/langRouter";
import Cookies from "@/components/Cookies.vue";

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: { Cookies, SiteNav },
  computed: {
    ready() {
      return this.$beet.isReady
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function(to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  }
}
</script>
