export default {
    nav: {
        home: 'Accueil',
        business: 'Répertoire',
        studies: 'Réalisations',
        tools: 'Ressources',
        SignUp: `S'inscrire`,
        login: 'Se connecter'
    },
    cookie: 'Nous utilisons des cookies et d’autres technologies de suivi pour améliorer votre expérience de navigation sur notre site Web, pour analyser le trafic de notre site Web et pour comprendre d’où viennent nos visiteurs.',
    cookieMore: 'Obtenir plus d’informations.',
    cookieButton: 'Compris!',
    policy: 'Politique de confidentialité',
    international: 'Cette organisation exporte ses produits ou effectue de la R&I à l’international, plus précisément dans ces marchés: ',
    services: 'Solutions et services',
    strengths: 'Forces et avantages compétitifs',
    customers: 'Clientèle',
    readAlso: 'À lire aussi',
    moreDetails: 'Obtenir plus de détails',
    responsability: '*L’organisation est responsable de maintenir sa fiche et les informations qu’elle contient à jour. Nous n’avons aucune influence ou lien sur les relations qu’elle entretient avec les autres organisations de ce répertoire.',
    visitWebsite: 'Visiter le site web',
    contactOrg: 'Contacter l’organisation',
    context: 'Contexte du projet',
    positive: 'Retombées positives',
    noResults: 'Aucun résultat ne correspond à votre recherche',
    noResultsButton: 'Réinitialiser les filtres',
    noResultsFilters: 'Veuillez supprimer ou modifier des filtres ou généraliser les mots-clés utilisés.',
    mapSlide: 'Glisser pour explorer la carte',

    signup: {
        name: 'Nom de l’organisation',
        neq: 'NEQ (facultatif)',
        sector: 'Secteur qui vous décrit le mieux',
        website: 'Site web de l’entreprise ( https://example.com )',
        username: 'Votre nom',
        email: 'Votre adresse courriel',
        description: 'Courte description de l’organisation',
        confirm: 'Je confirme que j’ai visité le répertoire et que mon organisation n’y a pas déjà de fiche.',
        cancel: 'Annuler',
        send: 'Envoyer la demande',
        note: 'Si votre organisation répond aux critères, vous recevrez bientôt une invitation à vous créer un compte afin d’y remplir votre fiche.',
        confirmation: {
            title: 'Nous avons bien reçu votre demande d’inscription!',
            text: 'Un membre de notre équipe révisera vos réponses afin de confirmer votre entrée dans l’écosystème. Par la suite, il est de votre responsabilité de vous créer un compte, de remplir votre fiche et de la maintenir à jour.',
            good: 'Découvrez les bons coups de l’écosystème',
            actors: 'Laissez-nous vous convaincre par nos acteurs et partenaires de l’industrie maritime québécoise et leurs collaborations.'
        }
    },
    '404': {
        title: 'Erreur 404',
        subtitle: 'Oups! La page que vous recherchez n’existe pas ou a été déplacée.',
        button: 'Retourner à l’accueil',
        sectionTitle: 'Découvrez les bons coups de l’écosystème',
        sectionText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt maximus cursus. Donec tincidunt semper dolor id tincidunt. Nunc nec ultricies dui.'
    }
}
