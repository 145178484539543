import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home')
    },
    {
        path: '/repertoire',
        paths: {en: '/directory'},
        name: 'businessIndex',
        component: () => import('@/views/BusinessIndex.vue')
    },
    {
        path: '/repertoire/:slug',
        paths: {en: '/directory/:slug'},
        name: 'businessShow',
        component: () => import('@/views/BusinessShow.vue')
    },
    {
        path: '/realisations',
        paths: {en: '/studies'},
        name: 'studiesIndex',
        component: () => import('@/views/StudiesIndex.vue')
    },
    {
        path: '/realisations/:slug',
        paths: {en: '/studies/:slug'},
        name: 'studiesShow',
        component: () => import('@/views/StudiesShow.vue')
    },
    {
        path: '/ressources',
        paths: {en: '/tools'},
        name: 'tools',
        component: () => import('@/views/Tools.vue')
    },
    {
        path: '/sinscrire',
        paths: {en: '/signup'},
        name: 'signUp',
        component: () => import('@/views/SignUp.vue')
    },
    {
        path: '/politique',
        paths: {en: '/policy'},
        name: 'policy',
        component: () => import('@/views/Policy.vue')
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes
})

router.beforeEach((to, from, next) => {
    if(to.name !== from.name && !to.hash) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        next()
    }
})

export default router
