<template>
  <transition mode="out-in" appear name="slide-up">
    <div class="cookies" v-if="showCookies">
      <p>
        {{ $t('cookie') }}
        <LangRouterLink :to="{name: 'policy'}" class="link"> {{ $t('cookieMore') }} </LangRouterLink>
      </p>

      <button class="button -fit cookies__button" @click="confirm"> {{ $t('cookieButton') }} </button>
    </div>
  </transition>

</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink.vue";
import { bootstrap } from 'vue-gtag'
export default {
  name: "Cookies",
  components: {LangRouterLink},
  data() {
    return {
      showCookies: false
    }
  },
  mounted() {
    if(!window.localStorage.getItem('cookies')) {
      setTimeout(() => {
        this.showCookies = true
      }, 5000)
    } else {
      this.activateApis()
    }
  },
  methods: {
    confirm() {
      this.showCookies = false
      window.localStorage.setItem('cookies', '1')
      this.activateApis()
    },
    activateApis() {
      bootstrap().then(() => {})
    }
  }
}
</script>
