<template>
  <div class="navbar">
    <div class="navbar__section">
      <LangRouterLink :to="{name: 'home'}">
        <img :src="mq.mdMinus ? '/img/CapSTL_logo.svg' : `/img/CapSTL_logo-complet-${$i18n.locale}.svg`" width="136" height="35"
             class="navbar__logo"/>
      </LangRouterLink>
    </div>

    <div class="navbar__section -center">


      <div class="navbar__links">
        <LangRouterLink :to="{name:'home'}" class="navbar__link">{{ $t('nav.home') }}</LangRouterLink>
        <LangRouterLink :to="{name:'businessIndex'}" class="navbar__link"
                        :class="{'-active' : routeName.includes('business')}">
          {{ $t('nav.business') }}
        </LangRouterLink>
        <LangRouterLink :to="{name:'studiesIndex'}" class="navbar__link"
                        :class="{'-active' : routeName.includes('studies')}"> {{ $t('nav.studies') }}
        </LangRouterLink>
        <LangRouterLink :to="{name:'tools'}" class="navbar__link">{{ $t('nav.tools') }}</LangRouterLink>
      </div>

      <div class="navbar__section navbar__burger" @click="open = !open">
        <transition name="fade" mode="out-in" duration="150">
          <Icon id="menu-close" v-if="open"/>
          <Icon id="menu" v-else/>
        </transition>
      </div>
    </div>

    <div class="navbar__section -end navbar__actions">
      <LangRouterLink :to="{name:'signUp'}" class="button">{{ $t('nav.SignUp') }}</LangRouterLink>
      <a :href="apiUrl" class="button -nowrap">{{ $t('nav.login') }}</a>
      <div class="navbar__link navbar__lang" @click="$i18n.locale = otherLang.toLowerCase()"> {{ otherLang }}</div>
    </div>

    <transition name="fade" appear>
      <div class="navbar-menu" v-if="open">
        <div class="col">
          <LangRouterLink :to="{name:'home'}" class="navbar-menu__link">{{ $t('nav.home') }}</LangRouterLink>
          <LangRouterLink :to="{name:'businessIndex'}" class="navbar-menu__link"
                          :class="{'-active' : routeName.includes('business')}">
            {{ $t('nav.business') }}
          </LangRouterLink>
          <LangRouterLink :to="{name:'studiesIndex'}" class="navbar-menu__link"
                          :class="{'-active' : routeName.includes('studies')}"> {{ $t('nav.studies') }}
          </LangRouterLink>
          <LangRouterLink :to="{name:'tools'}" class="navbar-menu__link">{{ $t('nav.tools') }}</LangRouterLink>
        </div>

        <div class="row w-100">
          <LangRouterLink :to="{name:'signUp'}" class="button -full">{{ $t('nav.SignUp') }}</LangRouterLink>
          <a :href="apiUrl" class="button -full -nowrap">{{ $t('nav.login') }}</a>
        </div>

        <div class="navbar-menu__lang">
          <div @click="$i18n.locale = otherLang.toLowerCase()"> {{ otherLang }}</div>
        </div>


        <div class="navbar-menu__note">
          <img src="/img/Quebec-noir.png" class="navbar-menu__note-image"/>
          <p>Ce projet est une initiative du Ministère des Relations Internationales et de la Francophonie du
            gouvernement du Québec et de ses partenaires.</p>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink.vue";

export default {
  name: "SiteNav",
  components: {LangRouterLink},
  inject: ['mq'],
  data() {
    return {
      open: false
    }
  },
  watch: {
    '$route.name'(to, from) {
      if (to !== from) this.open = false
    }
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL.slice(0, -3);
    },
    otherLang() {
      return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
    },
    routeName() {
      return this.$route.name || ''
    }
  }
}
</script>
