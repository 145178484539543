export default {
    home: 'home',
    about: 'about',
    nav: {
        home: 'Home',
        business: 'Directory',
        studies: 'Case Studies',
        tools: 'Tools',
        SignUp: `Sign up`,
        login: 'Login'
    },

    cookie: 'We use cookies and other tracking technologies to enhance your browsing experience on our website, to analyze our website traffic, and to understand where our visitors are coming from.',
    cookieMore: 'Get more information.',
    cookieButton: 'Understood!',
    policy: 'Privacy policy',
    international: 'This organization exports its products or conducts R&D internationally, specifically in these markets:',
    services: 'Solutions and Services',
    strengths: 'Strengths and Competitive Advantages',
    customers: 'Customers',
    readAlso: 'Read also',
    moreDetails: 'Get more details',
    responsability: '*The organization is responsible for maintaining its profile and the information it contains. We have no influence or connection to the relationships it maintains with other organizations in this directory.',
    visitWebsite: 'Visit website',
    contactOrg: 'Contact organization',
    context: 'Project Context',
    positive: 'Positive Impacts',
    noResults: 'No results match your search',
    noResultsButton: 'Reset filters',
    noResultsFilters: 'Please remove or modify filters or broaden the keywords used.',
    mapSlide: 'Slide to explore the map',
    signup: {
        name: 'Organization Name',
        neq: 'NEQ (optional)',
        sector: 'Sector that best describes you',
        website: 'Company website ( https://example.com )',
        username: 'Your name',
        email: 'Your email address',
        description: 'Brief description of the organization',
        confirm: 'I confirm that I have visited the directory and that my organization does not already have a profile.',
        cancel: 'Cancel',
        send: 'Send request',
        note: 'If your organization meets the criteria, you will soon receive an invitation to create an account to fill out your profile.',
        confirmation: {
            title: 'We have received your registration request!',
            text: 'A member of our team will review your answers to confirm your entry into the ecosystem. Afterwards, it is your responsibility to create an account, fill out your profile and keep it up to date.',
            good: 'Discover the successes of the ecosystem',
            actors: 'Let us convince you with our actors and partners in the Quebec maritime industry and their collaborations.'
        }
    },
    '404': {
        title: 'Error 404',
        subtitle: 'Oops! The page you are looking for does not exist or has been moved.',
        button: 'Return to home',
        sectionTitle: 'Discover the successes of the ecosystem',
        sectionText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt maximus cursus. Donec tincidunt semper dolor id tincidunt. Nunc nec ultricies dui.'
    }
}
